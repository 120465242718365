import { AxiosApi } from '../../config';

interface TimePeriod {
  year: string;
  monthName: string;
}

const getCurrentMonthReport = async (resId: string, currentMonth: string) => {
  return AxiosApi.get(`get-restaurant-report/${resId}?monthName=${currentMonth}`, {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
    },
  });
};

const getCurrentMonthStatement = async (resId: string, currentMonth: string) => {
  return AxiosApi.get(`get-restaurant-statement/${resId}?monthName=${currentMonth}`, {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
    },
  });
};


export { getCurrentMonthReport, getCurrentMonthStatement };
