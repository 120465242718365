import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import AddressMap from '../map/address-map';

type Anchor = 'right';

interface MapsDrawerProps {
  updateLocation?: (cords: { latitude: string | number; longitude: string | number }) => void;
}

const MapsDrawer: React.FC<MapsDrawerProps> = ({ updateLocation }) => {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const onLocationUpdate = (Location: { latitude: number; longitude: number }) => {
    if (updateLocation) {
      updateLocation(Location);
      setState({ right: false });
    }
  };

  const drawerContent = (
    <Box
      sx={{ width: 500, paddingTop: 10 }}
      role="presentation"
      //onClick={toggleDrawer('right', false)}
      //onKeyDown={toggleDrawer('right', false)}
      bgcolor="white" // Set the background color to white
    >
      {/* You can add any content you want here */}
      {state.right && <AddressMap sendLocation={onLocationUpdate} />}
    </Box>
  );

  return (
    <div>
      <Button variant="contained" onClick={toggleDrawer('right', true)}>
        Add/Edit Restaurant Location
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {drawerContent}
      </SwipeableDrawer>
    </div>
  );
};

export default MapsDrawer;
