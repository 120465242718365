import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Alert } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DescriptionIcon from '@mui/icons-material/Description';
import styled from 'styled-components';
import { PersonalDetailsForm } from './forms/personal-details';
import { RestaurantDetailsForm } from './forms/restaurant-details';
import { DcoumentsUploadForm } from './forms/documents-upload';
import { useAppSelector } from '../../store';
import { PreViewComponent } from './forms/preview-form';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
enum APPLICATION_STATUS {
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
}

const steps = ['Personal Details', 'Restaurant Details', 'Documents', 'Preview'];
const ICONS = [PersonIcon, StorefrontIcon, DescriptionIcon, PersonIcon];

const RestaurantForm = () => {
  console.log('componenst renders stepper');

  const [activeStep, setActiveStep] = useState < number > (0);
  const [skipped, setSkipped] = useState < Set < number >> (new Set());
  const masterData = useAppSelector((state: any) => state?.masterReducer.data);
  const navigate = useNavigate();

  const handleNext = (): void => {
    console.log(activeStep);

    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1;
    });
  };

  const handleBack = (): void => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleFormChange = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleApproute = () => {
    navigate('/dashboard/store-management');
  };

  useEffect(() => {
    if (!masterData) return;

    if (
      APPLICATION_STATUS.SUBMITTED === masterData?.applicationStatus ||
      APPLICATION_STATUS.ACCEPTED === masterData?.applicationStatus
    ) {
      setActiveStep(4);
    } else {
      stepperSetter();
    }
  }, [masterData?.applicationStatus]);

  const stepperSetter = (): void => {
    const checkStep = (data: any, step: number): void => {
      if (Object.keys(data?.other).length && data?.other.activeStep === 'true') {
        setActiveStep(step);
      }
    };

    checkStep(masterData?.ownerProfile, 1);
    checkStep(masterData?.profile, 2);
    checkStep(masterData?.documents, 3);
  };

  useEffect(() => {
    console.log(activeStep);
  }, [activeStep]);

  return (
    <Box sx={{ width: '100%' }}>
      <Alert severity="info">15% commission will be charged on each order user placed.</Alert>

      {masterData && (
        <Stepper activeStep={activeStep} sx={{ marginTop: 0 }}>
          {steps.map((label, index) => {
            const stepProps = { completed: false };
            const labelProps = { optional: null as React.ReactNode };

            if (index === 0) {
              labelProps.optional = <Typography variant="caption">Basic Details</Typography>;
            }

            if (index === 1) {
              labelProps.optional = (
                <Typography variant="caption">Location, Open & Close hrs.</Typography>
              );
            }

            if (index === 2) {
              labelProps.optional = <Typography variant="caption">FSSAI, PAN, GSTIN</Typography>;
            }

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} StepIconComponent={ICONS[index]}>
                  <Typography variant="h6" component="div" fontWeight={'bold'}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
      {activeStep === steps.length ? (
        <Box sx={{ mt: 2, mb: 1 }}>
          <CenteredDiv>
            <div className="card card-container">
              .{' '}
              <svg
                width="100px"
                height="100px"
                className="success"
                viewBox="0 0 70 70"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g
                    transform="translate(-1233.000000, -901.000000)"
                    stroke="#34D183"
                    strokeWidth="4"
                  >
                    <g transform="translate(1237.000000, 905.000000)" className="path circle">
                      <circle className="path circle" cx="32" cy="32" r="32"></circle>
                      <polyline
                        className="path check"
                        points="48 22.7096774 26.6484279 42.3225806 15.483871 31.5557034"
                      ></polyline>
                    </g>
                  </g>
                </g>
              </svg>
              <h2 style={{ wordWrap: 'break-word' }}>
                {APPLICATION_STATUS.ACCEPTED === masterData?.applicationStatus
                  ? ' Your application is approved!'
                  : 'Thank You!'}
              </h2>
              <p className="card-p">
                {APPLICATION_STATUS.SUBMITTED === masterData?.applicationStatus ? (
                  'Application under processing.'
                ) : (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
                      <HomeBtnContainer onClick={handleApproute}>
                        <StorefrontIcon />
                      </HomeBtnContainer>
                    </div>
                  </>
                )}
              </p>
            </div>
          </CenteredDiv>
        </Box>
      ) : (
        <Box sx={{ mt: 2, mb: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="outlined"
            >
              Back
            </Button>
            <Button onClick={handleNext} variant="outlined">
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
          {activeStep === 0 && <PersonalDetailsForm onNext={handleFormChange} />}
          {activeStep === 1 && <RestaurantDetailsForm onNext={handleFormChange} />}
          {activeStep === 2 && <DcoumentsUploadForm onNext={handleFormChange} />}
          {activeStep === 3 && <PreViewComponent onNext={handleFormChange} />}
        </Box>
      )}
    </Box>
  );
};

export { RestaurantForm };

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  width: 100%;
`;

const HomeBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
