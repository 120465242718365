import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const FAQPage = () => {
  return (
    <Box maxWidth="600px" margin="auto" padding="20px">
      <Typography variant="h4" align="center" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {/* defaultExpanded */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6">To register your restaurant on our platform, follow these simple steps:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ol>
              <li>Start by logging in to the platform using your phone number. You'll receive an OTP (One-Time Password) for verification.</li>
              <li>After OTP verification, fill out the application form provided. This form requires personal details and essential restaurant information.</li>
              <li>As part of the application process, you'll need to upload necessary documentation, including your TIN (Taxpayer Identification Number), Aadhar card, and PAN (Permanent Account Number) card.</li>
              <li>Once you've completed the form and uploaded the required documents, submit your application. Our team will review it within 24 hours.</li>
              <li>Upon approval, you'll receive a message upon login as "Application approved", granting you access to the restaurant panel. From there, you can start adding dishes to your restaurant menu and manage your offerings effectively.</li>
            </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6">What is the commission?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our commission structure is designed to be fair and transparent, ensuring that both parties benefit from our partnership. Commission rates may vary depending on factors such as order volume, location, and marketing support. Rest assured, we strive to provide competitive rates that align with industry standards while delivering exceptional value to our restaurant partners.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6">What personal details are required for restaurant registration?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            During the registration process, you'll be asked to provide personal details such as your name, contact information, and email address. This information helps us verify your identity and communicate important updates regarding your restaurant account.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6">Why do I need to provide my PAN card and TIN numbers?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We require your PAN (Permanent Account Number) card and TIN (Taxpayer Identification Number) to verify your restaurant's tax compliance and legal status. This information is necessary for regulatory purposes and helps ensure that your restaurant operates within legal guidelines.
          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6">How is the authorization process for adding dishes to my restaurant conducted?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            After registering your restaurant and providing the necessary personal and tax-related information, our team will review your application for authorization to add dishes to your restaurant menu. Once your information is verified and your restaurant is approved, you'll receive authorization to start adding dishes.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6">What types of dishes can I add to my restaurant menu?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can add a variety of dishes to your restaurant menu, including appetizers, main courses, desserts, and beverages. Feel free to showcase your culinary specialties and offerings to attract customers to your restaurant.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6">Is there a limit to the number of dishes I can add to my restaurant menu?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            There is no strict limit to the number of dishes you can add to your restaurant menu. However, we recommend maintaining a balanced and manageable menu to ensure quality and efficiency in food preparation and service.

          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6">How do I update my restaurant information or menu items?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can easily update your restaurant information and menu items through the restaurant panel dashboard. Simply log in to your account, navigate to the relevant sections, and use the provided tools to make changes or additions to your menu.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6">What should I do if I encounter technical issues or need assistance with the restaurant panel?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you encounter any technical issues or need assistance with the restaurant panel, our support team is here to help. You can reach out to us through the email - tharasafoodadda@gmail.com and we'll promptly address your concerns and provide assistance as needed.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6">How to download the app ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can always visit <a href="https://play.google.com/store/apps/details?id=com.lightworks.foodapp">here</a> for more information.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQPage;
