import { Alert, Button, Grid, Paper, TextField, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import FileUpload from '../components/fileUpload';
import { DocumentsType } from '../../../types';
import { Patterns } from '../../../constant';
import { useDispatch } from 'react-redux';
import { MasterDocumentsDetails } from '../../../store/reducers/master-reducer';
import { DocumentsInfo, UpdateDocuments } from '../../../store/services/FormService';
import { useAppSelector } from '../../../store';
import { toast } from 'react-toastify';
interface FormState extends Partial<DocumentsType> {
  pan: string;
  fssai: string;
  tan: string;
  gstin: string;
  license: string;
  foodLicense: string;
  other?: any;
  extras?: any;
}

const DcoumentsUploadForm: React.FC<{
  onNext?: (step: number) => void;
  isAccountView?: boolean;
}> = ({ onNext, isAccountView }) => {
  const theme = useTheme();
  const [formState, setFormState] = useState<FormState>({
    pan: '',
    fssai: '',
    tan: '',
    gstin: '',
    license: '',
    foodLicense: '',
    other: {
      ownerAadharNumber: '',
      activeStep: 'true',
    },
    extras: {},
  });
  const [licenses, setLicenses] = useState<File[]>([]);
  const [kitchenPictures, setKitchenPictures] = useState<File[]>([]);
  const dispatch = useDispatch();
  const masterData = useAppSelector((state: any) => state.masterReducer.data);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if (!validateForm()) return;
    const documents: DocumentsType = {
      ...formState,
      other: {
        ...formState.other,
        activeStep: 'true',
      },
      extras: {
        ...formState.extras,
        licenses: licenses,
        kitchenPictures: kitchenPictures,
      },
    };
    console.log(documents);
    // if(!validateForm()){
    //   const alertMsgElement = document.getElementById("alertMsg");
    //   if (alertMsgElement) {
    //     const offset = alertMsgElement.offsetTop - 250; // Subtracting 100 pixels
    //     window.scrollTo({ top: offset, behavior: "smooth" });
    //   }
    //   return
    // }
    const payload: DocumentsInfo = {
      documents: documents,
    };
    try {
      if (masterData) {
        const Response = await UpdateDocuments(payload, masterData.restaurantId);
        const res = JSON.parse(JSON.stringify(Response));
        if (res.status === 200) {
          dispatch(MasterDocumentsDetails(documents));
        }
      }
    } catch (error) {}
    if (onNext) {
      onNext(3);
    }

    if (masterData) {
    }
  };

  const validateForm = () => {
    let isValid = true;

    let keys = Object.keys(formState);
    keys.forEach((key: string) => {
      switch (key) {
        case 'pan':
          if (!Patterns.PANCARD.test(formState.pan)) {
            isValid = false;
            toast.warning('Invalid PAN card number.');
            return isValid;
          }
          break;
        case 'fssai':
          if (!Patterns.FSSAI_NUMBER.test(formState.fssai)) {
            isValid = false;
            toast.warning('Invalid FSSAI number.');
            return isValid;
          }
          break;
        case 'tan':
          if (!Patterns.TAN_NUMBER.test(formState.tan)) {
            isValid = false;
            toast.warning('Invalid TAN number.');
            return isValid;
          }
          break;
        case 'gstin':
          if (!Patterns.GSTIN_NUMBER.test(formState.gstin)) {
            isValid = false;
            toast.warning('Invalid GSTIN number.');
            return isValid;
          }
          break;
        case 'other':
          if (!Patterns.AADHAAR_NUMBER.test(formState.other.ownerAadharNumber)) {
            isValid = false;
            toast.warning('Invalid Aadhaar number.');
            return isValid;
          }
          break;

        default:
          break;
      }
    });

    return isValid;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(event.target.value);

    switch (name) {
      case 'other.ownerAadharNumber':
        setFormState({
          ...formState,
          other: {
            ...formState.other,
            ownerAadharNumber: value,
          },
        });
        break;
      default:
        setFormState({
          ...formState,
          [name]: value,
        });
    }
  };

  const getLicenseDocuments = (documents: File[]) => {
    setLicenses(documents);
  };

  const getKitchenDocuments = (documents: File[]) => {
    setKitchenPictures(documents);
  };

  const handleFileRemoveLicenses = (documents: File[]) => {
    setLicenses(documents);
  };

  const handleFileRemovePictures = (documents: File[]) => {
    setKitchenPictures(documents);
  };

  useEffect(() => {
    if (!!masterData) {
      const updatedFormState: any = { ...formState };
      Object.keys(formState).forEach((key) => {
        if (masterData.documents.hasOwnProperty(key)) {
          updatedFormState[key] = masterData.documents[key];
        }
      });
      setFormState(updatedFormState);
    } else {
      setFormState({
        pan: '',
        fssai: '',
        tan: '',
        gstin: '',
        license: '',
        foodLicense: '',
        other: {
          ownerAadharNumber: '',
          activeStep: 'true',
        },
        extras: {},
      });
    }
  }, [masterData]);

  useEffect(() => {
    if (!Object.keys(masterData?.documents?.extras).length) return;

    if (masterData?.documents?.extras.licenses) {
      setLicenses([...masterData.documents.extras.licenses] || []);
    }
    if (masterData?.documents?.extras.kitchenPictures) {
      setKitchenPictures([...(masterData.documents.extras.kitchenPictures || [])]);
    }
  }, [masterData]);

  return (
    <>
      <Paper
        component="form"
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          margin: 'auto',
          gap: '16px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Owner Aadhar Number"
              name="other.ownerAadharNumber"
              value={formState.other.ownerAadharNumber}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="PAN Number"
              name="pan"
              value={formState.pan}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="fssai"
              name="fssai"
              value={formState.fssai}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="gstin"
              name="gstin"
              value={formState.gstin}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="foodLicense"
              name="foodLicense"
              value={formState.foodLicense}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tan Number"
              name="tan"
              value={formState.tan}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="left">
              Shop License / FSSAI / GSTIN / PAN / TAN
            </Typography>
            {/* Add more input fields as needed */}
            <FileUpload
              uploadedfiles={licenses}
              name="instance1"
              key={'instance1'}
              isMultiple={true}
              onFileUpload={getLicenseDocuments}
              copyText="Upload Files here"
              accept="image,csv"
              size="20"
              resId={masterData?.restaurantId}
              type='document'
              onRemoveFile={handleFileRemoveLicenses}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="left">
              Pictures of Facade / Kitchen / Dining or Packaging / Locality
            </Typography>
            {/* Add more input fields as needed */}
            <FileUpload
              uploadedfiles={kitchenPictures}
              name="instance2"
              key={'instance2'}
              isMultiple={true}
              onFileUpload={getKitchenDocuments}
              copyText="Upload Files here"
              accept="image,csv"
              size="20"
              resId={masterData?.restaurantId}
              type='document'
              onRemoveFile={handleFileRemovePictures}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            height: '88px',
            alignItems: 'end',
          }}
        >
          <Button variant="contained" type="submit">
            {isAccountView ? 'Update' : 'Save and Next'}
          </Button>
        </Grid>
      </Paper>
    </>
  );
};

export { DcoumentsUploadForm };
