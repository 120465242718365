import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import { useAppDispatch, useAppSelector } from '../store';
import { logIntoApp } from '../store/reducers/user-reducer';
import { LinearProgress } from '@mui/material';

interface PrivateRouteProps {
  children: JSX.Element;
  isLoaderActive: boolean;
}

const DashboardPrivateRoute: FC<PrivateRouteProps> = ({ children, isLoaderActive }) => {
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();
  const authToken = useAppSelector((state) => state.userReducer.token);

  useEffect(() => {
    const token = localStorage.getItem('auth-token');
    const restaurantId = localStorage.getItem('restaurant-id');
    if (token && restaurantId) {
      dispatcher(logIntoApp({ token, restaurantId }));
    } else {
      navigate('/login');
    }
  }, [authToken]);

  return (
    <>
      {isLoaderActive && <LinearProgress />}
      <Layout isLoaderActive={isLoaderActive}>{children}</Layout>
    </>
  );
};

export default DashboardPrivateRoute;
