import axios from 'axios';
const APIUrl = 'https://api.lightworks-services.com/admin/restaurant/'; //Home
const PublicUrl = 'https://api.lightworks-services.com/public/restaurant/';
const AxiosApi = axios.create({
  baseURL: APIUrl,
  publicUrl: PublicUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export { AxiosApi, APIUrl, PublicUrl };
