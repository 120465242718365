interface DateParams {
    yearParam: number;
    monthParam: string;
    dateOfMonth: number;
  }
  
  const monthNameList = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
  
  const getYearList = (from = new Date().getFullYear()) => {
    const currentDate = new Date();
    const presentYear = currentDate.getFullYear();
    const yearList = [from];
    if (from === presentYear || from > presentYear) return yearList;
    let diff = presentYear - from;
    let temp = from;
    while (diff !== 0) {
      temp += 1;
      yearList.push(temp);
      diff--;
    }
  
    return yearList;
  };
  
  const getMonthNumberByName = (monthName: string) => {
    return monthNameList.indexOf(monthName) !== -1
      ? monthNameList.indexOf(monthName) + 1
      : new Date().getMonth() + 1;
  };
  
  const daysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate();
  
  const getDateOptions = (details?: DateParams) => {
    const currentDate = new Date();
    const presentYear = details?.yearParam || currentDate.getFullYear();
    const dateOfTheMonth = details?.dateOfMonth || currentDate.getDate();
    const monthName = details?.monthParam || monthNameList[currentDate.getMonth()];
  
    const month = getMonthNumberByName(monthName);
  
    const startOfMonth = new Date(presentYear, month - 1, 1);
    const endOfMonth = new Date(presentYear, month, 0, 23, 59, 59, 999);
  
    const startOfDay = new Date(presentYear, month - 1, dateOfTheMonth, 0, 0, 0, 0);
    const endOfDay = new Date(presentYear, month - 1, dateOfTheMonth, 23, 59, 59, 999);
  
    const daysPerMonth = daysInMonth(presentYear, month);
  
    return {
      presentYear,
      monthName: String(monthName),
      monthNumber: month,
      dateOfTheMonth,
      daysPerMonth,
      startOfMonth,
      endOfMonth,
      startOfDay,
      endOfDay,
    };
  };
  
  export { getDateOptions, monthNameList, getYearList, daysInMonth, getMonthNumberByName };
  