import { Box } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { GetOrders } from '../../store/services/OrderService';
import { OrderDetails } from '../../store/reducers/order-reducer';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent, Typography, Chip } from '@mui/material';
import { OrderCard } from './components';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const enum ORDER_STATUS {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
}

export const Orders = () => {
  const userData = useAppSelector((state) => state.userReducer);
  const [rows, setRows] = useState([]);
  const dispatch = useAppDispatch();
  const [selectedOrderData, setSelectedOrderData] = useState < any > ();
  const [openOrderData, setOpenOrderData] = useState(false);
  const [year, setYear] = useState < any > (dayjs(new Date()));
  const [month, setMonth] = useState < any > (dayjs(new Date()));
  const [open, setOpen] = useState(false);
  const fetchData = async (event?: any, evenType?: 'year' | 'month') => {

    const payload = {
      year: dayjs(new Date(year)).year().toString(),
      monthName: getMonthName(month),
    }

    if (evenType === 'year') {
      const datestring = dayjs(new Date(event));
      setYear(() => datestring);
      payload.year = `${dayjs(new Date(event)).year()}`
    }

    if (evenType === 'month') {
      const datestring = dayjs(new Date(event));
      setMonth(() => datestring);
      payload.monthName = getMonthName(datestring as unknown as string)
    }

    console.log(dayjs(event).year());

    try {
      const res = await GetOrders(userData.restaurantId, payload);

      if (res.status === 200) {
        setRows(res?.data?.data);
        dispatch(OrderDetails(res?.data?.data));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getMonthName = (dateString: string) => {
    const monthNames = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];
    const monthIndex = new Date(dateString).getUTCMonth();

    const monthName = monthNames[monthIndex];
    return monthName;
  };

  useEffect(() => {
    if (!userData.restaurantId) return;
    fetchData(dayjs(new Date()), 'year');
  }, []);

  // const columns: GridColDef[] = useMemo(
  //   () => [
  //     { field: '_id', headerName: 'Order Id', width: 130 },
  //     { field: 'orderAmount', headerName: 'Amount', width: 150 },
  //     { field: 'orderStatus', headerName: 'Order status', width: 130 },
  //   ],
  //   [],
  // );

  const handleCopy = (event: any, orderId: string) => {
    event.stopPropagation()
    navigator.clipboard.writeText(orderId);
    setOpen(true);
  }

  const getClass = (status: string) => {
    switch (status) {
      case ORDER_STATUS.UNASSIGNED:
        return 'error';
      case ORDER_STATUS.ASSIGNED:
        return 'success';
    }
    return 'success';
  };

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'orderNumber', headerName: 'Order number', width: 100 ,renderCell: (params) => {
        return (
          <>
            <div className='center-container'>
            <span>{params.row.orderNumber}</span>
            </div>
          </>
          
        );
      },},
      {
        field: '_id', headerName: 'Order Id', width: 250, renderCell: (params) => {
          return (
            <>
              <div className='orderId-container'>
                <span>{params.row._id}</span>
                <ContentCopyIcon sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={(e) => handleCopy(e, params.row._id)} />
              </div>
            </>

          );
        },
      },
      { field: 'orderAmount', headerName: 'Order Amount', width: 130 },
      { field: 'orderStatus', headerName: 'Order status', width: 130 },
      { field: 'foodStatus', headerName: 'Order status', width: 130 },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 150,
        renderCell: (params) => {
          return (
            <div>
              {new Date(Number(params.row.createdTime)).toDateString()}
            </div>
          );
        },
      }
    ],
    [],
  );
  const handleRowSelection = (event: any) => {

    setSelectedOrderData(event.row);
    setOpenOrderData(true);
  };

  const calculateHeight = (height: number) => height - 200;

  return (
    <Box sx={{ height: calculateHeight(window.innerHeight), width: '100%' }}>
      <Typography variant="h4" color="initial">
        Orders
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <DatePicker
            label={'"year"'}
            openTo="year"
            maxDate={dayjs()}
            value={year}
            views={['year']}
            onChange={(event: any) => fetchData(event, 'year')}
          />
          <DatePicker
            value={month}
            label={'"month"'}
            openTo="month"
            views={['month']}
            onChange={(event: any) => fetchData(event, 'month')}
          />
        </DemoContainer>
      </LocalizationProvider>

      <DataGrid
        sx={{ marginTop: '8px' }}
        getRowId={(row) => row._id}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[5]}
        onRowClick={handleRowSelection}
        {...rows}
      />
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              maxWidth: '500px',
            },
          },
        }}
        open={openOrderData}
        onClose={() => setOpenOrderData(false)}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #0000001A',
          }}
        >
          <DialogTitle>Order Details</DialogTitle>
          <div style={{ padding: '0px 12px', cursor: 'pointer' }}>
            <CloseIcon color="error" onClick={() => setOpenOrderData(false)} />
          </div>
        </div>

        <DialogContent>
          <OrderCard orderData={selectedOrderData} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
