import {
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../../keys/firebase';
import { AxiosApi } from '../../config';

const ENV_HOST = 'https://api.lightworks-services.com';
const ENV_PREFIX = 'admin/restaurant';

const signin = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password).then((response: UserCredential) => {
    return response.user;
  });
};

const signup = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password).then((response) => {
    return response.user;
  });
};

const sendOtp = async (mNumber: string) => {
  return AxiosApi.post(
    `${ENV_HOST}/${ENV_PREFIX}/sendOtp`,
    { mobileNumber: mNumber },
    {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
      },
      withCredentials: true,
    },
  );
};

const verifytOtp = async (mNumber: string, OTP: string) => {
  return AxiosApi.post(
    `${ENV_HOST}/${ENV_PREFIX}/verifyOtp`,
    { loginId: mNumber, otp: OTP },
    {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
      },
    },
  );
};

export { signin, signup, sendOtp, verifytOtp };
