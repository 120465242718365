import { Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store";
import { getCurrentMonthReport, getCurrentMonthStatement } from "../../store/services/report-services";
import { theme } from "../../theme";
import { getDateOptions, monthNameList } from "../../util";


const getDefaultReportData = (isDay = true) => {
    const d = getDateOptions();
    return {
        "totalOrders": 0,
        "ordersCompleted": 0,
        "ordersNoResponse": 0,
        "ordersRejected": 0,
        "ordersActive": 0,
        "restaurantShare": 0,
        "settledOrders": 0,
        "unsettledOrders": 0,
        "ownerShare": 0,
        "year": d.presentYear,
        "monthName": d.monthName,
        "day": d.dateOfTheMonth,
        "isDay": isDay
    };
}

const Reports = () => {
    const userData = useAppSelector((state) => state.userReducer);
    const d = getDateOptions();
    const [dailyReport, setDailyreport] = useState(getDefaultReportData());
    const [monthreport, setMonthreport] = useState(getDefaultReportData(false));
    const [isDayReport, setIsDayReport] = useState(true);
    const [alignment, setAlignment] = useState('web');
    const [currentMonth, setCurrentMonth] = useState(d.monthName);
    const [currentStatement, setCurrentStatement] = useState<{
        id: string,
        orderNumber: number,
        orderDate: string,
        restaurantShare: number
        isSettled: boolean
    }[]>([]);


    const fetchMonthlyReport = async () => {
        try {
            if (!userData.restaurantId) return;
            const resp = await getCurrentMonthReport(userData.restaurantId, currentMonth);
            if (resp && resp.status === 200 && resp.data.isSuccess) {
                if (resp.data.data && resp.data.data.dayReport) {
                    setDailyreport(resp.data.data.dayReport);
                }
                if (resp.data.data && resp.data.data.monthReport) {
                    setMonthreport(resp.data.data.monthReport);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const fetchMonthlyStatement = async () => {
        try {
            if (!userData.restaurantId) return;
            const resp = await getCurrentMonthStatement(userData.restaurantId, currentMonth);
            if (resp && resp.status === 200 && resp.data.isSuccess) {
                if (resp.data.data && resp.data.data.dayStatement && isDayReport) {
                    setCurrentStatement(resp.data.data.dayStatement);
                } else {
                    setCurrentStatement(resp.data.data.monthStatement);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }


    const process = async () => {
        try {
            await fetchMonthlyReport();
            await fetchMonthlyStatement();
        } catch (e) {
            console.log(e);
        }
    }

    const getReportData = () => {
        if (isDayReport) return dailyReport;
        return monthreport;
    }

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (newAlignment === "daily") {
            setIsDayReport(true);
            setCurrentMonth(d.monthName);
        } else {
            setIsDayReport(false);
        }
        setAlignment(newAlignment);
    };

    useEffect(() => {
        process();
    }, [alignment, currentMonth]);

    const currentData = getReportData();


    const columns: GridColDef<(typeof currentStatement)[number]>[] = [
        // { field: 'id', headerName: 'Id', width: 90, renderCell: () => Date.now()  },
        { field: 'orderNumber', headerName: 'Order Number', width: 200 },
        {
            field: 'orderDate',
            headerName: 'order time',
            width: 150,
            renderCell: (params) => { return <>{new Date(Number(params.row.orderDate)).toDateString()}</> }
        },
        {
            field: 'restaurantShare',
            headerName: 'Amount',
            width: 150,
            sortable: true,
        },
        {
            field: 'isSettled',
            headerName: 'Order Settled',
            width: 150,
            sortable: true,
        },
    ];

    return (
        <Box
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: "column"
            }}
        >
            <Stack margin={5} flexDirection={"row"} alignItems={"center"} gap={2}>
                <Typography variant="h5">Reports</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="daily">Today's</ToggleButton>
                    <ToggleButton value="monthly">Monthly</ToggleButton>
                    {/* <ToggleButton value="ios" disabled>Yearly</ToggleButton> */}
                </ToggleButtonGroup>

                {!isDayReport && <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['YearCalendar', 'MonthCalendar']}>
                        <DatePicker
                            defaultValue={dayjs(new Date())}
                            label={'Choose month'}
                            openTo="month"
                            views={['month']}
                            onChange={(event: any) => setCurrentMonth(monthNameList[new Date(event).getMonth()])}
                        />
                    </DemoContainer>
                </LocalizationProvider>}
            </Stack>
            <Grid container spacing={2} gap={2} display={"flex"} justifyContent={"center"}>
                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Total orders</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.totalOrders}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Orders completed</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.ordersCompleted}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.main}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Orders rejected</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.ordersRejected}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Orders no reponse</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.ordersNoResponse}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Settled orders</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.settledOrders}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Unsettled orders</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.unsettledOrders}</Typography>
                        </Stack>
                    </Stack>
                </Grid>


                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Active orders</Typography>

                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.ordersActive}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Restaurant share</Typography>
                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.restaurantShare}</Typography>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid xs={12} sm={4} md={2} p={2} display={"flex"} style={{ border: `2px solid ${theme.palette.primary.dark}`, borderRadius: 15 }}>
                    <Stack display={"flex"} flex={1} >
                        <Stack flex={1} >
                            <Typography variant="caption">Owner share</Typography>
                        </Stack>
                        <Stack flex={8} alignItems={"flex-end"} >
                            <Typography variant="h1">{currentData.ownerShare}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Stack height={400} m={10}>
                <DataGrid
                    rows={currentStatement}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Stack>
        </Box>
    )
}

export default Reports; 