import { createSlice } from '@reduxjs/toolkit';

interface Cls_InitialState {
  adminStoreData: any;
  publicStoreData: any;
  reviewHistoryData: any;
}
const initialState: Cls_InitialState = {
  adminStoreData: null,
  publicStoreData: null,
  reviewHistoryData: null,
};

export const StoreSlice = createSlice({
  name: 'StoreDetails',
  initialState: initialState,
  reducers: {
    AdminStoreDetails: (state, action) => {
      if (state.adminStoreData) {
        state.adminStoreData = { ...state.adminStoreData, ...action.payload };
      } else {
        state.adminStoreData = action.payload;
      }
    },
    PublicStoreDetails: (state, action) => {
      if (state.publicStoreData) {
        state.publicStoreData = { ...state.publicStoreData, ...action };
      } else {
        state.publicStoreData = action.payload;
      }
    },

    ReviewHistoryDetails: (state, action) => {
      if (state.reviewHistoryData) {
        state.reviewHistoryData = { ...state.reviewHistoryData, ...action };
      } else {
        state.reviewHistoryData = action.payload;
      }
    },

    UpdateAdminStoreByDishId: (state, action) => {
      if (state.adminStoreData[action.payload.dishId]) {
        state.adminStoreData[action.payload.dishId] = action.payload;
      }
    },

    UpdatePublicStoreByDishId: (state, action) => {
      console.log(action.payload);
      if (state.publicStoreData[action.payload.dishId]) {
        state.publicStoreData[action.payload.dishId] = action.payload;
      }
      console.log(state.publicStoreData[action.payload.dishId]);
    },

    DeleteAdminStoreDish: (state, action) => {
      if (state.adminStoreData[action.payload.dishId]) {
        delete state.adminStoreData[action.payload.dishId];
      }
    },

    PostAdminStoreDetails: (state, action) => {
      state.adminStoreData = {
        ...state.adminStoreData,
        ...{
          [action.payload.id]: { ...action.payload },
        },
      };
    },
  },
});

export const {
  AdminStoreDetails,
  PublicStoreDetails,
  UpdateAdminStoreByDishId,
  UpdatePublicStoreByDishId,
  DeleteAdminStoreDish,
  ReviewHistoryDetails,
  PostAdminStoreDetails,
} = StoreSlice.actions;

export default StoreSlice.reducer;
