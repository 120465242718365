import { configureStore } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import { enableMapSet } from 'immer'; // Import enableMapSet from Immer
import userReducer from './reducers/user-reducer';
import formReducer from './reducers/form-reducer';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import masterReducer from './reducers/master-reducer';
import storeReducer from './reducers/store-reducer';
import orderReducer from './reducers/order-reducer';

const asyncFunctionMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  enableMapSet();
  console.log('REDUX: Middleware invoked...');
  if (typeof action === 'function') {
    return action(storeAPI.dispatch, storeAPI.getState);
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    userReducer: userReducer,
    formReducer: formReducer,
    masterReducer: masterReducer,
    masterStore: storeReducer,
    orderReducer: orderReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(asyncFunctionMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
