import { useEffect, useState } from 'react';
import DishCard, { DishData } from './dish/dish-card';
import { useAppDispatch, useAppSelector } from '../../store';
import { PublicDishes } from '../../store/services/DishService';
import { Alert, Box, CardMedia, Grid, Tab, Typography } from '@mui/material';
import styled from 'styled-components';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import { PublicStoreDetails } from '../../store/reducers/store-reducer';
import { DEFAULT_COVER_PHOTO } from '../../constants/assets';

const StoreView = () => {
  const userData = useAppSelector((state) => state.userReducer);
  const publicStoreData = useAppSelector((state) => state.masterStore.publicStoreData);
  const storeViewData: any = useAppSelector((state) => state.masterReducer.data);
  const [coverImage, setCoverImage] = useState < any[] > ([]);

  const getCoverImage = (documents: any[]) => {
    setCoverImage(documents);
  };

  const STORE_IMAGE: string = storeViewData?.storeView?.image || DEFAULT_COVER_PHOTO;
  const COVER_PHOTO: string = coverImage[0] ? coverImage[0]?.url : STORE_IMAGE;

  const dispatch = useAppDispatch();
  const fetchPublicViewData = async () => {
    try {
      if (!publicStoreData) {
        const res = await PublicDishes(userData.restaurantId);

        if (res.status === 200) {
          const menuObjectData = JSON.parse(JSON.stringify(res.data.data.menu));
          let keys = Object.keys(menuObjectData);
          let tempMenuData: any = [];

          keys.forEach((menuId) => {
            tempMenuData.push(menuObjectData[menuId]);
          });

          setPublicMenuData(tempMenuData);
          dispatch(PublicStoreDetails(menuObjectData));
        } else {
          // Handle other non-200 status codes or specific error scenarios
          console.error('Error fetching public data:', res.status, res.data.message);
        }
      } else {
        const menuObjectData = JSON.parse(JSON.stringify(publicStoreData));
        let keys = Object.keys(menuObjectData);
        let tempMenuData: any = [];

        keys.forEach((menuId) => {
          tempMenuData.push(menuObjectData[menuId]);
        });

        setPublicMenuData(tempMenuData);
      }
    } catch (error) {
      console.error('Unexpected error during data fetching:', error);
    }
  };

  const [publicMenuData, setPublicMenuData] = useState < DishData[] > ([]);

  useEffect(() => {
    if (!userData.restaurantId) return;
    fetchPublicViewData();
  }, [userData]);

  return (
    <>
      <Typography variant="h4"> Store</Typography>
      <div style={{ padding: '5px' }}></div>
      <Alert
        icon={<OnlinePredictionIcon fontSize="inherit" />}
        sx={{
          marginBottom: '12px',
          alignItems: 'center',
        }}
      >
        Customer View - This is Live!
      </Alert>
      <CardMedia
        style={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
        component="img"
        height="400px"
        image={COVER_PHOTO}
      ></CardMedia>
      <ContainerDiv>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                position: 'absolute',
                top: '400px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: 'calc(100% - 150px)',
                alignItems: 'center',
              }}
            >
              {`Welcome to ${storeViewData?.storeView?.displayName}`}
            </Typography>
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          {publicMenuData.map((data: DishData, index: number) => (
            <Grid item xs={12} sm={12} md={4} key={index}>
              <DishCard dishData={data} isPreview={false} />
            </Grid>
          ))}
        </Grid>
      </ContainerDiv>
    </>
  );
};

export { StoreView };
const ContainerDiv = styled.div`
  padding: 20px;
  border-radius: 10px;
`;
