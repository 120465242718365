import { Alert, Button, CardMedia, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditableDishCard, { DishData } from './dish/editable-dish-card';
import styled from 'styled-components';
import { useAppSelector } from '../../store';
import DishCard from '../store/dish/dish-card';

export const StoreUnderReview = () => {
  const reviewHistoryData = useAppSelector((state) => state.masterStore.reviewHistoryData);
  const [reviewMenuData, setReviewMenuData] = useState([]);
  const [currentReviewId, setCurrentReviewId] = useState('');
  const storeViewData: any = useAppSelector((state) => state.masterReducer.data);

  const fetchCurrentReviewData = () => {
    const { currentReviewId, allReviews } = reviewHistoryData;
    setCurrentReviewId(currentReviewId);
    if (!currentReviewId) {
      setReviewMenuData([]);
      return;
    }
    const currentReviewMenuData = allReviews[currentReviewId];
    let tempMenuObject = currentReviewMenuData?.storeView?.menu;

    let keys = Object.keys(tempMenuObject);
    let tempMenuData: any = keys.map((dishId) => tempMenuObject[dishId]);
    setReviewMenuData(tempMenuData);
  };

  useEffect(() => {
    if (!reviewHistoryData) return;
    fetchCurrentReviewData();
  }, [reviewHistoryData]);

  return (
    <>
      {' '}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '100%',
          gap: '15px',
        }}
      >
        <Alert severity="warning">
          {reviewMenuData.length > 0
            ? `This current store is under review. Your review ID: ${currentReviewId}`
            : 'No store is under review'}
        </Alert>
        {reviewMenuData.length > 0 && (
          <div>
            <CardMedia
              style={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
              component="img"
              height="400px"
              image="https://img.freepik.com/free-photo/top-view-circular-food-frame_23-2148723455.jpg?w=1380&t=st=1703183685~exp=1703184285~hmac=4cf9c8189e11a5ae1188db62885e81e1c5a9b71997be06ac9b04be10bafcf7dc"
            />
            <ContainerDiv>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{
                      position: 'absolute',
                      top: '430px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: 'calc(100% - 180px)',
                      alignItems: 'center',
                    }}
                  >
                    {`Welcome to ${storeViewData?.storeView?.displayName}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {reviewMenuData.map((data: DishData, index: number) => (
                  <Grid item sm={12} md={6} lg={4} xs={12} key={index}>
                    <DishCard dishData={data} isPreview={true} />
                  </Grid>
                ))}
              </Grid>
            </ContainerDiv>
          </div>
        )}
      </div>
    </>
  );
};
const ContainerDiv = styled.div`
  padding: 20px;
  border-radius: 10px;
`;
