import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Chip, CardMedia, Switch } from '@mui/material';
import { DishStatus, ChangeDishStatus } from '../../../store/services/DishService';
import { useAppDispatch } from '../../../store';
import { UpdatePublicStoreByDishId } from '../../../store/reducers/store-reducer';
import getSymbolFromCurrency from 'currency-symbol-map';

export interface DishData {
  dishName: string;
  cuisine: string;
  description: string;
  price: number;
  currency: string;
  dishStatus: 'AVAILABLE' | 'UNAVAILABLE';
  images?: string[];
  isVeg: boolean;
  other?: { [key: string]: string };
  extras?: { [key: string]: any };
  dishId: string;
  restaurantId: string;
}
interface DishCardProps {
  dishData: DishData;
  isPreview: boolean;
}

const DishCard: React.FC<DishCardProps> = ({ dishData, isPreview }) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [dishViewData, setDishViewData] = useState<DishData>(dishData);
  const [dishStatus, setDishStatus] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleChange = async () => {
    const payload: DishStatus = {
      status: !dishStatus ? 'AVAILABLE' : 'UNAVAILABLE',
      dishId: dishViewData.dishId,
    };

    const res = await ChangeDishStatus(payload, dishViewData.restaurantId);

    if (res?.status === 200) {
      setDishStatus((prevDishStatus) => !prevDishStatus);
      const updatedDish = {
        ...dishViewData,
        dishStatus: payload.status,
      };
      dispatch(UpdatePublicStoreByDishId(updatedDish));
      setDishViewData((prevDishViewData) => ({
        ...prevDishViewData,
        dishStatus: payload.status,
      }));
    }
  };

  useEffect(() => {
    setDishViewData(dishData);
    setImageUrls(dishData?.images || []);
    setDishStatus(dishData?.dishStatus === 'AVAILABLE');
  }, [dishData]);

  return (
    <Card elevation={3} style={{ maxWidth: '400px', margin: '20px auto' }}>
      <CardMedia
        component="img"
        alt={dishViewData?.dishName}
        height="140"
        className={dishViewData?.dishStatus === 'UNAVAILABLE' ? 'img-gray' : ''}
        image={
          imageUrls?.length
            ? imageUrls[0]
            : 'https://i.pinimg.com/originals/ee/1d/08/ee1d081c5bdf966b058c1a6588e73e8a.gif'
        }
      />
      {!isPreview && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          <Switch checked={dishStatus} onChange={handleChange} />
        </div>
      )}
      <CardContent>
        <Typography variant="h4" component="div">
          {dishViewData?.dishName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {dishViewData?.cuisine}
        </Typography>
        <Typography variant="body2" paragraph>
          {dishViewData?.description}
        </Typography>
        <Typography variant="body1">
          Price: {getSymbolFromCurrency(dishViewData?.currency)} {dishViewData?.price}
        </Typography>
        <Typography variant="body1" style={{ marginTop: '5px' }}>
          Status: <Chip label={dishViewData?.dishStatus} color="primary" />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DishCard;
