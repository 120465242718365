import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { AddressSchemaType, OwnerProfileSchemaType } from '../../../types';
import { Country, State, City } from 'country-state-city';
import { connect, useDispatch } from 'react-redux';
import { FormDetails } from '../../../store/reducers/form-reducer';
import { OwnerInfoUpdate } from '../../../store/services/FormService';
import { Patterns } from '../../../constant';
import { useAppSelector } from '../../../store';
import { MasterOwnerDetails } from '../../../store/reducers/master-reducer';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

// Define the initial form state
interface FormState extends Partial<OwnerProfileSchemaType> {
  name: string;
  email: string;
  aadhar: string;
  pan: string;
  dob: string;
  publicPhone: string;
  privatePhone: string;
  whatsappNumber: string;
  address: FormAddressState;
  other: {
    activeStep: string;
  };
}

interface FormAddressState extends Partial<AddressSchemaType> {
  addressId: string;
  area: string;
  address: string;
  flatOrHouseOrBlockNo: string;
  houseOrApptName: string;
  landmark: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

interface FinalPayload {
  ownerProfile: FormState;
}

// Define the React component
const PersonalDetailsForm: React.FC<{
  onNext?: (step: number) => void;
  isAccountView?: boolean;
}> = ({ onNext, isAccountView }) => {
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [dob, setDob] = useState<any>('');
  const dispatch = useDispatch();
  const masterData = useAppSelector((state: any) => state?.masterReducer?.data);

  // Use the custom styles
  const theme = useTheme();

  const classes = {
    form: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 400,
      margin: 'auto',
    },
    input: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
      alignSelf: 'center',
    },
  };

  // Use the useState hook to manage the form state
  const [formState, setFormState] = useState<FormState>({
    name: '',
    email: '',
    aadhar: '',
    pan: '',
    dob: '',
    publicPhone: '',
    privatePhone: '',
    whatsappNumber: '',
    address: {
      addressId: '',
      area: '',
      address: '',
      flatOrHouseOrBlockNo: '',
      houseOrApptName: '',
      landmark: '',
      type: '',
      city: '',
      state: '',
      country: '',
      postcode: '',
      location: {
        latitude: 0,
        longitude: 0,
      },
    },
    other: {
      activeStep: 'true',
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMsg('');

    if (!validateForm(formState)) {
      const alertMsgElement = document.getElementById('alertMsg');
      if (alertMsgElement) {
        const offset = alertMsgElement.offsetTop - 250; // Subtracting 100 pixels
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
      return;
    }
    const payload = {
      ownerProfile: formState,
    };
    try {
      if (masterData) {
        const res = await OwnerInfoUpdate(payload, masterData.restaurantId);
        if (res.status === 200) {
          if (onNext) {
            onNext(1);
          }
          dispatch(MasterOwnerDetails(payload));
        }
      }
    } catch (error) {}
  };

  const validateForm = (formData: any, path: string[] = []): boolean => {
    const exceptionalKeys = [
      'publicPhone',
      'addressId',
      'type',
      'latitude',
      'longitude',
      'pan',
      'privatePhone',
    ];

    for (const key in formData) {
      const currentPath: string[] = [...path, key];
      const value = formData[key];

      if (exceptionalKeys.includes(key)) {
        continue;
      }

      if (value !== null && typeof value === 'object') {
        const isValid = validateForm(value, currentPath);
        if (!isValid) {
          return false;
        }
      } else {
        if (!value) {
          toast.warning(`Please enter a value for ${currentPath.join('.')}`, {
            toastId: 'warning',
          });
          return false;
        }

        if (key === 'email' && !Patterns.EMAIL.test(value)) {
          toast.warning(`Please enter a valid email address for ${currentPath.join('.')}`, {
            toastId: 'warning',
          });
          return false;
        }

        if (key === 'aadhaar' && !Patterns.AADHAAR_NUMBER.test(value)) {
          toast.warning(
            `Please enter a valid 12-digit Aadhaar number for ${currentPath.join('.')}`,
            {
              toastId: 'warning',
            },
          );
          return false;
        }

        if (key === 'pan' && !Patterns.PANCARD.test(value)) {
          toast.warning(`Please enter a valid PAN number for ${currentPath.join('.')}`, {
            toastId: 'warning',
          });
          return false;
        }
      }
    }

    return true;
  };
  const onResetForm = () => {
    setFormState({
      name: '',
      email: '',
      aadhar: '',
      pan: '',
      dob: '',
      publicPhone: '',
      privatePhone: '',
      whatsappNumber: '',
      address: {
        addressId: '',
        area: '',
        address: '',
        flatOrHouseOrBlockNo: '',
        houseOrApptName: '',
        landmark: '',
        type: '',
        city: '',
        state: '',
        country: '',
        postcode: '',
        location: {
          latitude: 0,
          longitude: 0,
        },
      },
      other: {
        activeStep: 'true',
      },
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
      case 'email':
      case 'aadhar':
      case 'pan':
      case 'dob':
      case 'publicPhone':
      case 'privatePhone':
      case 'whatsappNumber':
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        break;

      case 'addressId':
      case 'area':
      case 'address':
      case 'flatOrHouseOrBlockNo':
      case 'houseOrApptName':
      case 'landmark':
      case 'type':
      case 'city':
      case 'state':
      case 'country':
      case 'postcode':
        setFormState((prevState) => ({
          ...prevState,
          address: {
            ...prevState.address,
            [name]: value,
          },
        }));
        break;

      case 'latitude':
      case 'longitude':
        setFormState((prevState) => ({
          ...prevState,
          address: {
            ...prevState.address,
            location: {
              ...prevState.address.location,
              [name]: parseFloat(value),
            },
          },
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeCountry = async (event: any) => {
    const { isoCode, name } = event.target.value;

    setFormState((prevFormState) => ({
      ...prevFormState,
      address: {
        ...prevFormState.address,
        country: name,
        state: '', // Reset state when country changes
        city: '', // Reset city when country changes
      },
    }));

    let states: any[] = await State.getStatesOfCountry(isoCode);
    setStateList(states as any);
    setCityList([]);
  };

  const handleChangeState = async (event: any) => {
    const { isoCode, countryCode, name } = event.target.value;

    setFormState((prevFormState) => ({
      ...prevFormState,
      address: {
        ...prevFormState.address,
        state: name,
        city: '', // Reset city when state changes
      },
    }));

    let cities: any[] = await City.getCitiesOfState(countryCode, isoCode);
    setCityList(cities as any);
  };

  const handleChangeCity = (event: any) => {
    const { name } = event.target.value;

    // Update formState using the state updater function
    setFormState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        city: name,
      },
    }));
  };

  const handleDateChange = (date: any) => {
    console.log(date);
    const datestring = dayjs(date).format('MM/DD/YYYY');
    console.log(datestring);
    setFormState({
      ...formState,
      dob: datestring,
    });
  };

  useEffect(() => {
    if (masterData?.ownerProfile) {
      setFields(formState, masterData.ownerProfile);
      setDob(dayjs(masterData.ownerProfile.dob) as any);
    }
  }, [masterData]);

  function setFields(fields: any, masterData: any) {
    Object.keys(fields).forEach((key) => {
      if (typeof masterData[key] === 'object') {
        setFields(fields[key], masterData[key]); // Fix: pass nested fields and masterData[key]
      } else {
        let event = {
          target: {
            name: key,
            value: masterData[key],
          },
        };
        handleChange(event as ChangeEvent<HTMLInputElement>);
      }
    });
  }

  return (
    <>
      {errorMsg && (
        <Alert id="alertMsg" style={{ marginTop: '15px' }} severity="error">
          {errorMsg}
        </Alert>
      )}

      <Paper
        component="form"
        onSubmit={(event) => handleSubmit(event)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          margin: 'auto',
          gap: '16px',
        }}
      >
        <Typography variant="h4" align="left">
          Personal information (Owner)
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Full Name"
              name="name"
              value={formState.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="DOB"
                value={dob}
                slotProps={{ textField: { fullWidth: true } }}
                onChange={(newValue: any) => handleDateChange(newValue)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Whatsapp phone number"
              name="whatsappNumber"
              value={formState.whatsappNumber}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Secondary phone number (Optional)"
              name="publicPhone"
              value={formState.publicPhone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Aadhar Number"
              name="aadhar"
              value={formState.aadhar}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="PAN Number"
              name="pan"
              value={formState.pan}
              onChange={handleChange}
              required
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h4" align="left">
              Address
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Enter Address"
              name="address"
              value={formState.address.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Area"
              name="area"
              value={formState.address.area}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Flat or House or Block No"
              name="flatOrHouseOrBlockNo"
              value={formState.address.flatOrHouseOrBlockNo}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="House or Apartment Name"
              name="houseOrApptName"
              value={formState.address.houseOrApptName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Landmark"
              name="landmark"
              value={formState.address.landmark}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name="postcode"
              label="Postal Code"
              value={formState.address.postcode}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              required
              defaultValue=""
              labelId="demo-simple-select-label"
              value={formState.address.country}
              label="Country"
              name="country"
              fullWidth
              placeholder="Country"
              renderValue={() => formState.address.country}
              onChange={(event) => handleChangeCountry(event)}
            >
              {Array.isArray(countryList) && countryList.length > 0 ? (
                countryList.map((country: any, index: number) => (
                  <MenuItem key={index} value={country}>
                    {country.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true} value={'No option'}>
                  No Options
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">State</InputLabel>
            <Select
              required
              defaultValue=""
              readOnly={stateList.length > 0 ? false : true}
              labelId="demo-simple-select-label"
              value={formState.address.state}
              label="State"
              fullWidth
              renderValue={() => formState.address.state}
              placeholder="Select State"
              onChange={(event) => handleChangeState(event)}
            >
              {Array.isArray(stateList) && stateList.length > 0 ? (
                stateList.map((state: any, index) => (
                  <MenuItem key={index} value={state}>
                    {state.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true} value={'No option'}>
                  No Options
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">City</InputLabel>
            <Select
              required
              defaultValue=""
              fullWidth
              readOnly={cityList.length > 0 ? false : true}
              labelId="demo-simple-select-label"
              value={formState.address.city}
              label="City"
              renderValue={() => formState.address.city}
              placeholder="Select City"
              onChange={(event) => handleChangeCity(event)}
            >
              {Array.isArray(cityList) && cityList.length > 0 ? (
                cityList.map((city: any, index) => (
                  <MenuItem key={index} value={city}>
                    {city.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true} value={'No option'}>
                  No Options
                </MenuItem>
              )}
            </Select>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: '88px',
              alignItems: 'end',
            }}
          >
            <Button variant="contained" type="submit">
              {!isAccountView ? 'Save and Next' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export { PersonalDetailsForm };
export default connect()(PersonalDetailsForm);
