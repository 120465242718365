import React, { ReactNode, FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import SideBar, { DrawerHeader } from './SideBar';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store';
import { MasterDetails } from '../store/reducers/master-reducer';
import { GetAllDetailsByResId } from '../store/services/FormService';

interface LayoutProps {
  children?: ReactNode;
  isLoaderActive: boolean;
}

const Layout: FC<LayoutProps> = ({ children, isLoaderActive }) => {
  const [open, setOpen] = useState(false);
  const resID = useAppSelector((state) => state.userReducer.restaurantId);
  const dispatch = useAppDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const fetchData = async () => {
  //   try {
  //     const details = await GetAllDetailsByResId(resID);

  //     if (details?.status === 200) {
  //       const { data } = details;
  //       dispatch(MasterDetails(data.data));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching details:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (!resID) return;
  //   fetchData();
  // }, [resID]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header open={open} handleDrawerOpen={handleDrawerOpen} isLoaderActive={isLoaderActive} />
      <SideBar open={open} handleDrawerClose={handleDrawerClose} />
        <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, p: 3,overflow:'auto',height:'calc(100vh - 60px)',marginTop:'60px' }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
