import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { City, Country, State } from 'country-state-city';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { MasterProfileDetails } from '../../../store/reducers/master-reducer';
import { ProfileInfoUpdate } from '../../../store/services/FormService';
import { ProfileSchemaType } from '../../../types';
import MapsDrawer from './maps-address';
import { toast } from 'react-toastify';
import GMaps from '../../../components/maps/g-maps';

// Define the initial form state
interface FormState extends ProfileSchemaType {
  restaurantName: string;
  email: string;
  passKey: string;
  publicPhone: string;
  privatePhone: string;
  supportPhone: string;
  address: {
    location: {
      latitude: number;
      longitude: number;
    };
    area: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postcode: string;
  };
  other: {
    activeStep: string;
    openTime: string;
    closeTime: string;
  };
}

const RestaurantDetailsForm: React.FC<{
  onNext?: (step: number) => void;
  isAccountView?: boolean;
}> = ({ onNext, isAccountView }) => {
  const theme = useTheme();

  const classes = {
    form: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      maxWidth: 400,
      margin: 'auto',
    },
    input: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
      alignSelf: 'center',
    },
  };

  // Use the useState hook to manage the form state
  const [formState, setFormState] = useState<FormState>({
    restaurantName: '',
    email: '',
    passKey: '',
    publicPhone: '',
    privatePhone: '',
    supportPhone: '',
    address: {
      location: {
        latitude: 0,
        longitude: 0,
      },
      area: '',
      address: '',
      city: '',
      state: '',
      country: '',
      postcode: '',
    },
    other: {
      activeStep: 'true',
      openTime: '',
      closeTime: '',
    },
  });

  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const dispatch = useAppDispatch();
  const FormRestaurantData = useAppSelector((state: any) => state?.masterReducer?.data);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) return;

    let profileDetails: any = JSON.parse(JSON.stringify(formState));
    profileDetails.other = {
      ...profileDetails.other,
      activeStep: true,
    };
    const payload = {
      profile: profileDetails,
    };
    try {
      const res = await ProfileInfoUpdate(payload, FormRestaurantData.restaurantId);

      if (res.status === 200) {
        dispatch(MasterProfileDetails(profileDetails));
        if (onNext) {
          onNext(2);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Define a handler function for form change
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'restaurantName':
      case 'email':
      case 'passKey':
      case 'publicPhone':
      case 'privatePhone':
      case 'supportPhone':
        setFormState({
          ...formState,
          [name]: value,
        });
        break;
      case 'address.area':
        setFormState({
          ...formState,
          address: {
            ...formState.address,
            area: value,
          },
        });
        break;
      case 'address.state':
        setFormState({
          ...formState,
          address: {
            ...formState.address,
            state: value,
          },
        });
        break;
      case 'address.country':
        setFormState({
          ...formState,
          address: {
            ...formState.address,
            country: value,
          },
        });
        break;
      case 'address.city':
        setFormState({
          ...formState,
          address: {
            ...formState.address,
            city: value,
          },
        });
        break;
      case 'address.postcode':
        setFormState({
          ...formState,
          address: {
            ...formState.address,
            postcode: value,
          },
        });
        break;
      case 'other.openTime':
        setFormState({
          ...formState,
          other: {
            ...formState.other,
            openTime: value,
          },
        });
        break;
      case 'other.closeTime':
        setFormState({
          ...formState,
          other: {
            ...formState.other,
            closeTime: value,
          },
        });
        break;
      default:
        break;
    }
  };

  const updateRetaurantLocation = (cords: {
    latitude: string | number;
    longitude: string | number;
  }) => {
    setFormState({
      ...formState,
      address: {
        ...formState.address,
        location: {
          latitude: Number(cords.latitude),
          longitude: Number(cords.longitude),
        },
      },
    });
  };

  const validateForm = () => {
    if (!formState.restaurantName.trim()) {
      toast.warning('Restaurant name is required', {
        toastId: 'warning',
      });
      return false;
    }
    if (!formState.email.trim()) {
      toast.warning('Email is required', {
        toastId: 'warning',
      });
      return false;
    }
    // if (!Patterns.PHONE_NUMBER.test(formState.privatePhone)) {
    //   toast.warning("please Enter valid private Phone number", {
    //     toastId: "warning",
    //   });
    //   return false;
    // }
    // if (!Patterns.PHONE_NUMBER.test(formState.supportPhone)) {
    //   toast.warning("please Enter valid support Phone number", {
    //     toastId: "warning",
    //   });
    //   return false;
    // }
    // if (!Patterns.PHONE_NUMBER.test(formState.publicPhone)) {
    //   toast.warning("please Enter valid public Phone number", {
    //     toastId: "warning",
    //   });
    //   return false;
    // }
    return true;
  };

  const handleChangeCountry = async (event: any) => {
    const { isoCode, name } = event.target.value;

    setFormState({
      ...formState,
      address: {
        ...formState.address,
        country: name,
        city: '',
        state: '',
      },
    });

    let states: any[] = await State.getStatesOfCountry(isoCode);
    setStateList(states as any);
    setCityList([]);
  };

  const handleChangeState = async (event: any) => {
    const { isoCode, countryCode, name } = event.target.value;

    setFormState({
      ...formState,
      address: {
        ...formState.address,
        state: name,
      },
    });

    let cities: any[] = await City.getCitiesOfState(countryCode, isoCode);
    setCityList(cities as any);
  };

  const handleChangeCity = (event: any) => {
    const { name } = event.target.value;

    // Update formState using the state updater function
    setFormState({
      ...formState,
      address: {
        ...formState.address,
        city: name,
      },
    });
  };

  useEffect(() => {
    if (FormRestaurantData) {
      const updatedFormState: any = { ...formState };
      Object.keys(formState).forEach((key) => {
        if (FormRestaurantData?.profile.hasOwnProperty(key)) {
          updatedFormState[key] = FormRestaurantData?.profile[key];
        }
      });

      setFormState(updatedFormState);
    }
  }, [FormRestaurantData]);

  return (
    <>
      <Paper
        component="form"
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          margin: 'auto',
          gap: '16px',
        }}
      >
        <Typography variant="h4" align="left">
          Restaurant Details
        </Typography>
        {/* Add more input fields as needed */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Restaurant Name"
              name="restaurantName"
              value={formState.restaurantName}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pass Key"
              name="passKey"
              value={formState.passKey}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Public Phone"
              name="publicPhone"
              value={formState.publicPhone}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Private Phone"
              name="privatePhone"
              value={formState.privatePhone}
              onChange={handleChange}
              required
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Open Time"
              name="other.openTime"
              type="time"
              variant="outlined"
              value={formState.other.openTime}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Close Time"
              name="other.closeTime"
              type="time"
              value={formState.other.closeTime}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Support Phone"
              name="supportPhone"
              value={formState.supportPhone}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="left">
              Restaurant Address
            </Typography>
            <MapsDrawer updateLocation={updateRetaurantLocation} />
            {formState.address &&
              formState.address.location &&
              formState.address.location.latitude &&
              formState.address.location.longitude && (
                <GMaps
                  lat={formState.address.location.latitude || 0}
                  lng={formState.address.location.longitude || 0}
                />
              )}
          </Grid>
          {/* <Grid item xs={12}>
            <MapViewComponent
              longitude={formState.address.location.longitude}
              latitude={formState.address.location.latitude}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Area"
              name="address.area"
              value={formState.address.area}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Postal Code"
              name="address.postcode"
              value={formState.address.postcode}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              required
              defaultValue=""
              labelId="demo-simple-select-label"
              value={formState.address.country}
              label="Country"
              name="address.country"
              fullWidth
              placeholder="Country"
              renderValue={() => formState.address.country}
              onChange={(event) => handleChangeCountry(event)}
            >
              {Array.isArray(countryList) && countryList.length > 0 ? (
                countryList.map((country: any, index: number) => (
                  <MenuItem key={index} value={country}>
                    {country.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true} value={'No option'}>
                  No Options
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">State</InputLabel>
            <Select
              required
              defaultValue=""
              readOnly={stateList.length > 0 ? false : true}
              labelId="demo-simple-select-label"
              value={formState.address.state}
              label="State"
              fullWidth
              name="address.state"
              renderValue={() => formState.address.state}
              placeholder="Select State"
              onChange={(event) => handleChangeState(event)}
            >
              {Array.isArray(stateList) && stateList.length > 0 ? (
                stateList.map((state: any, index) => (
                  <MenuItem key={index} value={state}>
                    {state.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true} value={'No option'}>
                  No Options
                </MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel id="demo-simple-select-label">City</InputLabel>
            <Select
              required
              defaultValue=""
              fullWidth
              readOnly={cityList.length > 0 ? false : true}
              labelId="demo-simple-select-label"
              value={formState.address.city}
              label="City"
              name="address.city"
              renderValue={() => formState.address.city}
              placeholder="Select City"
              onChange={(event) => handleChangeCity(event)}
            >
              {Array.isArray(cityList) && cityList.length > 0 ? (
                cityList.map((city: any, index) => (
                  <MenuItem key={index} value={city}>
                    {city.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled={true} value={'No option'}>
                  No Options
                </MenuItem>
              )}
            </Select>
          </Grid>
          {/* <Grid item xs={12} >
       <Typography variant="h4" align="left">
        Personal Details
      </Typography>
      </Grid>
 
      <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Opening Time"
        name="openingTime"
        value={formState.op}
        onChange={handleChange}
        required
      />
      </Grid>
      <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Closing Time"
        name="closingTime"
        value={formState.closingTime}
        onChange={handleChange}
        required
      />
      </Grid>
      <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Food License"
        name="foodLicense"
        value={formState.foodLicense}
        onChange={handleChange}
        required
      />
      </Grid> */}
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: '88px',
              alignItems: 'end',
            }}
          >
            <Button variant="contained" type="submit">
              {isAccountView ? 'Update' : 'Save and Next'}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export { RestaurantDetailsForm };
