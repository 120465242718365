import React from 'react';
import { connect } from 'react-redux';
import { useAppSelector } from '../../../store';
import { theme } from '../../../theme';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { PostFinalSubmit } from '../../../store/services/FormService';
import EditIcon from '@mui/icons-material/Edit';
interface props {
  activeStepChanger?: (step: number) => void;
  onNext?: (step: number) => void;
}
const PreViewComponent: React.FC<props> = ({ activeStepChanger, onNext }) => {
  const masterData = useAppSelector((state: any) => state?.masterReducer?.data);
  const licenseImage = masterData.documents?.extras?.licenses
    ? masterData.documents?.extras?.licenses
    : [];
  const kitchenImage = masterData.documents?.extras?.kitchenPictures
    ? masterData.documents?.extras?.kitchenPictures
    : [];

  const handleSubmit = async () => {
    try {
      const res = await PostFinalSubmit(masterData.restaurantId);

      if (res.status === 200 && onNext) {
        onNext(3);
      }
    } catch (error) {
      console.error('An error occurred while submitting:', error);
    }
  };
  const handleEdit = (stepCount: number) => {
    if (!activeStepChanger) return;
    activeStepChanger(stepCount);
  };
  return (
    <>
      <Paper
        component="form"
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          margin: 'auto',
          gap: '16px',
        }}
      >
        <Grid container spacing={2} direction={'row'} alignItems={'center'}>
          <Grid item xs={10}>
            <Typography variant="h4" align="left">
              Personal information (Owner)
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <EditIcon onClick={() => handleEdit(0)} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Full Name"
            name="name"
            value={masterData.ownerProfile.name}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Date of birth"
            name="dob"
            value={masterData.ownerProfile.dob}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Email"
            name="email"
            value={masterData.ownerProfile.email}
            required
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Primary phone number"
            name="privatePhone"
            value={masterData.ownerProfile.privatePhone}
            required
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Whatsapp phone number"
            name="whatsappNumber"
            value={masterData.ownerProfile.whatsappNumber}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Secondary phone number (Optional)"
            name="publicPhone"
            value={masterData.ownerProfile.publicPhone}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="Aadhar Number"
            name="aadhar"
            value={masterData.ownerProfile.aadhar}
            required
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            label="PAN Number"
            name="pan"
            value={masterData.ownerProfile.pan}
            required
          />
        </Grid> */}

        {/* Address starts here */}

        <Grid item xs={12}>
          <Typography variant="h4" align="left">
            Address
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="Enter Address"
            name="address"
            value={masterData.ownerProfile.address.address}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="Area"
            name="area"
            value={masterData.ownerProfile.address.area}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="Flat or House or Block No"
            name="flatOrHouseOrBlockNo"
            value={masterData.ownerProfile.address.flatOrHouseOrBlockNo}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="House or Apartment Name"
            name="houseOrApptName"
            value={masterData.ownerProfile.address.houseOrApptName}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="Landmark"
            name="landmark"
            value={masterData.ownerProfile.address.landmark}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            name="postcode"
            label="Postal Code"
            value={masterData.ownerProfile.address.postcode}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="Country"
            value={masterData.ownerProfile.address.country}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="State"
            value={masterData.ownerProfile.address.state}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label="City"
            value={masterData.ownerProfile.address.city}
            required
          />
        </Grid>

        {/* Restaurant Details starts here */}

        <Grid container spacing={2} direction={'row'} alignItems={'center'}>
          <Grid item xs={10}>
            <Typography variant="h4" align="left">
              Restaurant Details
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <EditIcon onClick={() => handleEdit(1)} />
          </Grid>
        </Grid>
        {/* Add more input fields as needed */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Restaurant Name"
              name="restaurantName"
              value={masterData.profile.restaurantName}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Email"
              name="email"
              value={masterData.profile.email}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Pass Key"
              name="passKey"
              value={masterData.profile.passKey}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Public Phone"
              name="publicPhone"
              value={masterData.profile.publicPhone}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Private Phone"
              name="privatePhone"
              value={masterData.profile.privatePhone}
              required
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Support Phone"
              name="supportPhone"
              value={masterData.profile.supportPhone}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" align="left">
              Restaurant Address
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Area"
              name="address.area"
              value={masterData.profile.address.area}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Postal Code"
              name="address.postcode"
              value={masterData.profile.address.postcode}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Country"
              name="address.postcode"
              value={masterData.profile.address.country}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="State"
              name="address.postcode"
              value={masterData.profile.address.state}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="City"
              name="address.postcode"
              value={masterData.profile.address.city}
              required
            />
          </Grid>

          {/* Documents Page */}
        </Grid>
        <Grid container spacing={2} direction={'row'} alignItems={'center'}>
          <Grid item xs={10}>
            <Typography variant="h4" align="left">
              Documents Preview
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <EditIcon onClick={() => handleEdit(2)} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Owner Aadhar Number"
              name="other.ownerAadharNumber"
              value={masterData.documents.other.ownerAadharNumber}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="PAN"
              name="pan"
              value={masterData.documents.pan}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="FSSAI"
              name="fssai"
              value={masterData.documents.fssai}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="GSTIN"
              name="gstin"
              value={masterData.documents.gstin}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Food License"
              name="foodLicense"
              value={masterData.documents.foodLicense}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="Tan Number"
              name="tan"
              value={masterData.documents.tan}
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              inputProps={{ readOnly: true }}
              label="License"
              name="license"
              value={masterData.documents.license}
              required
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="left">
              Shop License / FSSAI / GSTIN / PAN / TAN
            </Typography>
            {licenseImage.length > 0 &&
              licenseImage.map((image: any, index: number) => (
                <img key={index} src={image.url} alt="" style={{ width: 440, height: 220 }} />
              ))}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="left">
              Pictures of Facade / Kitchen / Dining or Packaging / Locality
            </Typography>
            {kitchenImage.length > 0 &&
              kitchenImage.map((image: any, index: number) => (
                <img key={index} src={image.url} alt="" style={{ width: 440, height: 220 }} />
              ))}
          </Grid>
        </Grid>
        <Grid>
          <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Paper>
    </>
  );
};

export { PreViewComponent };
export default connect()(PreViewComponent);
